import { forwardRef } from 'react'
import { useInput, type UseInputProps } from './use-input'

export type InputProps = UseInputProps

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { styles, className, type } = useInput(props)

  return (
    <input
      ref={ref}
      {...props}
      type={type}
      className={styles.base({ className })}
    />
  )
})
Input.displayName = 'Input'

export { Input }
