import { tv, type VariantProps } from '../../lib/utils'

const dialogContentVariants = tv({
  slots: {
    overlay: [
      'grid place-items-center',
      'p-6',
      'overflow-y-auto',
      'fixed inset-0 z-50',
      'bg-black/80',
      'data-[state=open]:animate-in data-[state=open]:fade-in-0',
      'data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
    ],
    content: [
      'relative',
      'grid gap-4',
      'w-full',
      'rounded-2xl',
      'bg-white',
      'shadow-md',
      'duration-200',
      'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95 data-[state=open]:slide-in-from-top-[48%]',
      'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[state=closed]:slide-out-to-top-[48%]',
    ],
    close: [
      'absolute z-10',
      'focus:outline-none focus:ring-2 focus:ring-offset-2',
      'transition-opacity',
      'disabled:pointer-events-none',
      'data-[state=open]:bg-gray-100 data-[state=open]:text-gray-500',
    ],
    icon: ['h-4 w-4'],
  },
  variants: {
    appearance: {
      card: {
        content: ['p-8', 'sm:max-w-lg'],
        close: [
          'right-4 top-4',
          'rounded-md',
          'opacity-70 hover:opacity-100',
          'ring-offset-white',
          'focus:ring-gray-100',
        ],
      },
      full: {
        content: ['sm:max-w-4xl'],
        close: [
          '-right-4 -top-4',
          'flex items-center justify-center',
          'w-8 h-8',
          'rounded-full',
          'text-white',
          'bg-primary-500',
          'ring-offset-primary-500/40',
          'focus:ring-primary-100',
        ],
      },
    },
  },
  defaultVariants: {
    appearance: 'card',
  },
})

const dialogHeaderVariants = tv({
  base: ['flex flex-col', 'space-y-1.5', 'text-left'],
})

const dialogFooterVariants = tv({
  base: ['flex flex-col-reverse sm:flex-row sm:justify-end', 'sm:space-x-2'],
})

const dialogTitleVariants = tv({
  base: ['text-gray-900'],
})

const dialogDescriptionVariants = tv({
  base: ['text-sm text-gray-500'],
})

export type DialogContentVariantProps = VariantProps<
  typeof dialogContentVariants
>

export type DialogHeaderVariantProps = VariantProps<typeof dialogHeaderVariants>

export type DialogFooterVariantProps = VariantProps<typeof dialogFooterVariants>

export type DialogTitleVariantProps = VariantProps<typeof dialogTitleVariants>

export type DialogDescriptionVariantProps = VariantProps<
  typeof dialogDescriptionVariants
>

export {
  dialogContentVariants,
  dialogHeaderVariants,
  dialogFooterVariants,
  dialogTitleVariants,
  dialogDescriptionVariants,
}
