export interface BrandProps {
  className?: string
}

const Brand = ({ className = '' }: BrandProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 225 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6645 2.86997L31.2336 0L14.4624 31.6578C9.37113 30.4958 3.93474 32.8251 1.37588 37.6553C-1.67994 43.4235 0.5251 50.574 6.3001 53.6258C12.0751 56.6777 19.2346 54.4759 22.2905 48.7077C24.8493 43.8775 23.7193 38.0781 19.8933 34.5277L27.1947 20.7454L32.601 23.6024L29.6546 29.1642L35.0609 32.0213L38.0074 26.4594L32.601 23.6024L35.5475 18.0406L40.9538 20.8976L43.9003 15.3358L38.494 12.4788L35.5475 18.0406L30.1412 15.1836L36.6645 2.86997ZM9.20496 48.1425C11.948 49.5921 15.3489 48.5464 16.8004 45.8064C18.2519 43.0665 17.2044 39.67 14.4614 38.2204C11.7184 36.7709 8.31748 37.8166 6.86596 40.5565C5.41444 43.2964 6.46196 46.693 9.20496 48.1425Z"
        fill="currentColor"
      />
      <path
        d="M124.579 38.5948V48.2337C124.579 51.768 126.347 54.4991 130.486 54.4991C132.374 54.4991 133.339 54.2581 134.745 53.8163L134.222 49.9607C133.298 50.2418 132.455 50.4427 131.49 50.4427C129.602 50.4427 128.919 49.4788 128.919 47.6313V38.5948H134.464V35.1006H128.919V30H124.579V35.1006H121.927V38.5948H124.579Z"
        fill="#3C3C3C"
      />
      <path
        d="M193.719 38.5948V48.2337C193.719 51.768 195.487 54.4991 199.626 54.4991C201.515 54.4991 202.479 54.2581 203.885 53.8163L203.363 49.9607C202.439 50.2418 201.595 50.4427 200.631 50.4427C198.742 50.4427 198.059 49.4788 198.059 47.6313V38.5948H203.604V35.1006H198.059V30H193.719V35.1006H191.067V38.5948H193.719Z"
        fill="#3C3C3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.873 44.4969C205.873 50.6819 209.409 54.4974 215.477 54.4974C221.464 54.4974 225 50.6819 225 44.4969C225 38.2718 221.263 34.617 215.477 34.617C209.61 34.617 205.873 38.2718 205.873 44.4969ZM210.213 44.4568C210.213 40.561 211.941 38.2718 215.437 38.2718C218.932 38.2718 220.7 40.561 220.7 44.4568C220.7 48.3525 219.013 50.7623 215.437 50.7623C211.82 50.7623 210.213 48.3525 210.213 44.4568Z"
        fill="#3C3C3C"
      />
      <path
        d="M177.358 30.3045V42.8519H179.086L185.032 35.1006H189.975V35.5423L182.501 44.3781L190.497 53.5351V53.9769H185.555L179.086 46.0649H177.358V53.9769H173.018V30.3045L177.358 30.3045Z"
        fill="#3C3C3C"
      />
      <path
        d="M164.169 48.998V53.9782H168.388V35.1018H164.049V45.785C164.049 48.6366 162.16 50.5644 159.227 50.5644C156.655 50.5644 155.651 48.8775 155.651 46.5883V35.1018H151.311V47.7128C151.311 51.8094 153.2 54.5003 157.178 54.5003C160.312 54.5003 162.723 52.4921 163.767 48.998H164.169Z"
        fill="#3C3C3C"
      />
      <path
        d="M142.117 53.9791H137.817V35.1028H141.916V40.7657L142.278 40.8058C143.362 36.2273 145.13 34.902 147.863 34.902H148.546V39.7616H147.501C143.523 39.7616 142.117 41.1673 142.117 45.0229V53.9791Z"
        fill="#3C3C3C"
      />
      <path
        d="M112.41 54.4974C106.303 54.4974 104.294 52.0073 103.61 48.3927L107.508 47.5894C108.111 49.8787 109.437 51.3245 112.491 51.3245C114.781 51.3245 115.986 50.5213 115.986 48.9148C115.986 47.4288 114.942 46.7058 111.205 46.0231C106.182 45.019 104.414 43.3322 104.414 40.1995C104.414 36.7857 106.905 34.617 111.928 34.617C117.111 34.617 118.879 36.7456 119.643 40.0791L115.745 40.9225C115.223 38.593 114.098 37.7095 111.848 37.7095C109.638 37.7095 108.513 38.5529 108.513 39.9586C108.513 41.2839 109.316 42.2077 112.892 42.8503C118.478 43.8945 120.165 45.7821 120.165 48.7541C120.165 52.2483 117.835 54.4974 112.41 54.4974Z"
        fill="#3C3C3C"
      />
      <path
        d="M87.8883 53.9753V43.2921C87.8883 40.4405 89.817 38.5127 92.7101 38.5127C95.3219 38.5127 96.3265 40.1996 96.3265 42.4888V53.9753H100.626V41.3643C100.626 37.2677 98.7373 34.617 94.7996 34.617C91.6252 34.617 89.2143 36.5849 88.1696 40.0791H87.808V35.0989H83.5889V53.9753H87.8883Z"
        fill="#3C3C3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.6425 54.4974C64.5751 54.4974 61.0391 50.6819 61.0391 44.4969C61.0391 38.2718 64.776 34.617 70.6425 34.617C76.4286 34.617 80.1655 38.2718 80.1655 44.4969C80.1655 50.6819 76.6295 54.4974 70.6425 54.4974ZM70.6023 38.2718C67.1065 38.2718 65.3787 40.561 65.3787 44.4568C65.3787 48.3525 66.986 50.7623 70.6023 50.7623C74.1785 50.7623 75.8661 48.3525 75.8661 44.4568C75.8661 40.561 74.0981 38.2718 70.6023 38.2718Z"
        fill="#3C3C3C"
      />
      <path
        d="M49.8807 38.4324C52.8541 38.4324 53.9792 40.0389 54.5417 42.4487L58.6001 41.6856C57.8366 37.1874 55.3855 34.617 49.961 34.617C44.1749 34.617 40.438 38.3521 40.438 44.6977C40.438 51.0434 44.0945 54.4974 50.0414 54.4974C55.2248 54.4974 57.8768 51.8467 58.6402 47.469L54.6623 46.5051C54.0997 48.7943 53.055 50.6819 50.0012 50.6819C46.6259 50.6819 44.858 48.3525 44.858 44.5371C44.858 40.4004 46.7867 38.4324 49.8807 38.4324Z"
        fill="#3C3C3C"
      />
    </svg>
  )
}

export { Brand }
