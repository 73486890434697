'use client'

import {
  forwardRef,
  type ElementRef,
  type ComponentPropsWithoutRef,
  type HTMLAttributes,
} from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { X } from 'lucide-react'
import {
  DialogContentVariantProps,
  dialogContentVariants,
  dialogDescriptionVariants,
  dialogFooterVariants,
  dialogHeaderVariants,
  dialogTitleVariants,
} from './dialog.variants'
import { Typography } from '../typography'

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content> &
    DialogContentVariantProps
>(({ className, children, appearance = 'card', ...rest }, ref) => {
  const styles = dialogContentVariants({ appearance })

  return (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay
        className={styles.overlay()}
        style={{ overflow: 'auto' }}
      >
        <DialogPrimitive.Content
          ref={ref}
          className={styles.content({ className })}
          {...rest}
        >
          {children}
          <DialogPrimitive.Close className={styles.close()}>
            <X className={styles.icon()} />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>
        </DialogPrimitive.Content>
      </DialogPrimitive.Overlay>
    </DialogPrimitive.Portal>
  )
})

DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  return <div {...rest} className={dialogHeaderVariants({ className })} />
}

DialogHeader.displayName = 'DialogHeader'

const DialogFooter = ({
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  return <div {...rest} className={dialogFooterVariants({ className })} />
}

DialogFooter.displayName = 'DialogFooter'

const DialogTitle = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, children, ...rest }, ref) => {
  return (
    <DialogPrimitive.Title
      ref={ref}
      {...rest}
      className={dialogTitleVariants({ className })}
      asChild
    >
      <Typography
        as="h2"
        fontSize="heading-xxs"
        fontFamily="jakarta"
        fontWeight="bold"
      >
        {children}
      </Typography>
    </DialogPrimitive.Title>
  )
})

DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...rest }, ref) => {
  return (
    <DialogPrimitive.Description
      ref={ref}
      {...rest}
      className={dialogDescriptionVariants({ className })}
    />
  )
})

DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}
