'use client'

import { forwardRef, type ElementRef } from 'react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { useAvatar, type UseAvatarProps } from './use-avatar'

export type AvatarProps = UseAvatarProps

const Avatar = forwardRef<ElementRef<typeof AvatarPrimitive.Root>, AvatarProps>(
  (props, ref) => {
    const { styles, className, image, fallback, fallbackProps, ...rest } =
      useAvatar(props)

    return (
      <AvatarPrimitive.Root
        ref={ref}
        {...rest}
        className={styles.base({ className })}
      >
        {!!image && (
          <AvatarPrimitive.Image {...image} className={styles.image()} />
        )}
        {!!fallback && (
          <AvatarPrimitive.Fallback
            {...fallbackProps}
            className={styles.fallback({ className: fallbackProps?.className })}
            children={fallback}
          />
        )}
      </AvatarPrimitive.Root>
    )
  }
)

Avatar.displayName = AvatarPrimitive.Root.displayName

export { Avatar }
