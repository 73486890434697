import { tv, type VariantProps } from '../../lib/utils'

const labelVariants = tv({
  base: [
    'text-sm font-medium leading-none',
    'peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  ],
  variants: {
    hasError: {
      false: ['text-gray-900'],
      true: ['text-red-500'],
    },
  },
  defaultVariants: {
    hasError: false,
  },
})

export type LabelVariantProps = VariantProps<typeof labelVariants>

export { labelVariants }
