'use client'

import {
  forwardRef,
  type ElementRef,
  type ComponentPropsWithoutRef,
} from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { labelVariants, type LabelVariantProps } from './label.variants'

export type LabelProps = ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
  LabelVariantProps

const Label = forwardRef<ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className = '', hasError, ...props }, ref) => {
    return (
      <LabelPrimitive.Root
        ref={ref}
        className={labelVariants({ className, hasError })}
        {...props}
      />
    )
  }
)

Label.displayName = LabelPrimitive.Root.displayName

export { Label }
