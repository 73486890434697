import { tv, type VariantProps } from '../../lib/utils'

const avatarVariants = tv({
  slots: {
    base: [
      'relative',
      'flex shrink-0',
      'overflow-hidden',
      'rounded-full',
      'bg-white',
    ],
    image: ['aspect-square', 'h-full w-full'],
    fallback: [
      'flex items-center justify-center',
      'h-full w-full',
      'rounded-full',
      'bg-white',
    ],
  },
  variants: {
    size: {
      sm: {
        base: ['h-10 w-10'],
        fallback: ['text-base'],
      },
      md: {
        base: ['h-12 w-12'],
        fallback: ['text-lg'],
      },
      lg: {
        base: ['h-16 w-16'],
        fallback: ['text-3xl'],
      },
      xl: {
        base: ['h-24 w-24'],
        fallback: ['text-5xl'],
      },
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

export type AvatarVariantProps = VariantProps<typeof avatarVariants>

export { avatarVariants }
