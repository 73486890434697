export interface BrandIconProps {
  className?: string
}

const BrandIcon = ({ className = '' }: BrandIconProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 84 183"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.1326 -5.50203e-06H27.113L27.113 111.004C11.4983 115.191 0 129.442 0 146.378C0 166.604 16.398 183 36.6228 183C56.8476 183 73.2456 166.604 73.2456 146.378C73.2456 129.442 61.7474 115.191 46.1326 111.004V62.6777H65.0663V82.1795H84V62.6777H65.0663V43.176H84V23.6742H65.0663V43.176H46.1326L46.1326 -5.50203e-06ZM36.6228 163.774C46.2291 163.774 54.0187 155.985 54.0187 146.378C54.0187 136.771 46.2291 128.983 36.6228 128.983C27.0165 128.983 19.227 136.771 19.227 146.378C19.227 155.985 27.0165 163.774 36.6228 163.774Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { BrandIcon }
