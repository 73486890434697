import { type InputHTMLAttributes } from 'react'
import { inputVariants, type InputVariantProps } from './input.variants'

export interface UseInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    InputVariantProps {}

const useInput = (props: UseInputProps) => {
  const { className = '', type = 'text', ...componentProps } = props

  const styles = inputVariants()

  return {
    className,
    styles,
    type,
    ...componentProps,
  }
}

export { useInput }
