import { type ReactNode, type ComponentPropsWithoutRef } from 'react'
import type {
  Root as AvatarRootProps,
  AvatarImageProps,
  AvatarFallbackProps,
} from '@radix-ui/react-avatar'
import { avatarVariants, AvatarVariantProps } from './avatar.variants'

export type UseAvatarProps = AvatarVariantProps &
  ComponentPropsWithoutRef<typeof AvatarRootProps> & {
    image?: AvatarImageProps
    fallback?: ReactNode
    fallbackProps?: AvatarFallbackProps
  }

const useAvatar = (props: UseAvatarProps) => {
  const { className = '', size = 'md', ...rest } = props

  const styles = avatarVariants({ size })

  return {
    styles,
    className,
    ...rest,
  }
}

export { useAvatar }
