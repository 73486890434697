import { tv, type VariantProps } from '../../lib/utils'

const inputVariants = tv({
  slots: {
    base: [
      'flex',
      'h-10 w-full',
      'rounded-md',
      'border border-gray-100',
      'bg-transparent',
      'px-3 py-2',
      'text-sm',
      'file:border-0 file:bg-transparent file:text-sm file:font-medium',
      'placeholder:text-gray-400',
      'ring-offset-white',
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-500/40 focus-visible:ring-offset-2',
      'disabled:cursor-not-allowed disabled:opacity-50',
    ],
  },
})

export type InputVariantProps = VariantProps<typeof inputVariants>

export { inputVariants }
